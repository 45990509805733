import React from 'react'
import { FiHelpCircle } from 'react-icons/fi'
import BrandIcon from './BrandIcon'

export default function Info({ children, className, onClick }) {
  return (
    <div onClick={onClick} className={`flex justify-center items-center max-w-xl m-2 mx-auto text-sm ${onClick && 'cursor-pointer'} ${className}`}>
      <BrandIcon Icon={FiHelpCircle} className="relative w-10 h-10 flex-shrink-0 mr-3" />
      <div className="text-gray-700 text-left leading-tight -ml-1">{children}</div>
    </div>
  )
}
