import React from 'react'
import HelpCenter from './HelpCenter'
import Headers from './UI/Headers'
import I18n from './UI/I18n'
import { FaInstagram, FaFacebook, FaYoutube } from 'react-icons/fa'
import Button from './UI/Button'

export default function Footer({ showHelp }) {
  return (
    <>
      <Headers />
      <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/8689458.js"></script>
      <div className="flex flex-col justify-center w-full items-center bottom-0 py-10 text-white bg-gray-700">
        <div className="flex flex-col justify-center items-center mt-3 lg:mt-6 mb-6 lg:mb-16">
          <img src="/logo-white.svg" className=" h-16 lg:h-24 " />
          <a href="https://wizito.com" target="_blank" className="text-xs lg:text-sm mt-2 text-center italic">
            Une marque du groupe <u>Wizito</u>, partenaire de vos événements depuis 2015
            <br />
            Visitez notre site pour découvrir nos expériences pour les professionnels
          </a>
        </div>
        <div className="w-full flex flex-wrap justify-center lg:justify-between items-center text-center lg:text-left max-w-screen-lg mx-auto">
          <div>
            <div className="font-semibold text-lg">Réseaux sociaux</div>
            <div className="font-thin text-base">Suivez nous pour ne rien manquer</div>
            <div className="flex mt-2 justify-center items-center lg:justify-start mb-4">
              <a href="https://www.mariages.net/animation-mariage/smiley-box--e238142" target="_blank" title="Mariages.net" className="mr-4 border-2 border-white rounded shadow h-10 overflow-hidden"><img alt="Mariages.net" className="h-full transform scale-105" src="https://www.mariages.net/images/sellos/label-partenaire--pp238142.png" /></a>
              <a href="https://fr.trustpilot.com/review/smiley-box.be"><img src="https://cdn.trustpilot.net/brand-assets/1.0.3/logo-black.svg" className="block h-10 bg-white px-3 py-2 mr-4 rounded shadow border-2 border-white" /></a>
              <Button variant="icon-white" href="https://www.instagram.com/_smileybox/" Icon={FaInstagram} className="mr-4 text-gray-800" />
              <Button variant="icon-white" href="https://www.facebook.com/smileybox.bornephoto" Icon={FaFacebook} className="mr-4 text-gray-800" />
              <Button variant="icon-white" href="https://www.youtube.com/channel/UCrO4rwo8HASvO-HHJMgh6Cg" Icon={FaYoutube} className="mr-4 text-gray-800" />
            </div>
          </div>
          <div>
            <div className="font-semibold text-lg">Contact</div>
            <div className="font-thin text-base">+32 2 808 50 00 (appel non surtaxé) <br /> <a href="mailto:coucou@smiley-box.be" className="underline">coucou@smiley-box.be</a></div>
            <div><Button variant="white" className="mx-auto lg:mx-0 mt-2 text-gray-800" onClick={() => showHelp()}>Accéder à la FAQ</Button></div>
          </div>
        </div>
        <div className="border-t border-gray-300 w-full max-w-screen-lg py-8 mt-8 font-thin text-base flex flex-col lg:flex-row justify-between items-center">
          <div>
            <a href="/mention_legales.pdf" target="_blank" className="mr-4 underline">Mentions légales</a>
            <a href="/politique_confidentialite.pdf" target="_blank" className="mr-4 underline">Politique de confidentialité</a>
          </div>
          <div>© {new Date().getFullYear()} - Smiley Box</div>
        </div>
      </div>
    </>
  )
}
