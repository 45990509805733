import useInterval from '@use-it/interval'
import React, { useRef, useState } from 'react'
import { useSpring, animated } from 'react-spring'
import { useGesture } from 'react-use-gesture'
import { computeInnerSize } from '../../utils'

export default function Carrousel({ children, className }) {
  const [width, setWidth] = useState(500)
  const ref = useRef()
  const [{ x, hover }, set] = useSpring(() => ({ x: 0, hover: 0 }))
  useInterval(() => {
    if (!ref.current) return 
    setWidth(computeInnerSize(ref.current) - Math.min(window.innerWidth, 1024) + 100)
  }, 5000)
  useInterval(() => {
    if (hover.value === 0 && x.value > -width && width > window.innerWidth)
      set({ x: x.value - 1 })
  }, 50)
  const bind = useGesture(
    {
      onDrag: ({ offset: [mx] }) => { set({ x: mx, hover: 1 }) },
      onHover: ({ active }) => { set({ hover: active ? 1 : 0 }) }
    },
    {
      drag: { filterTaps: true, bounds: { left: -width, right: 0 }, rubberband: true, eventOptions: { passive: false, capture: true } }
    }
  )

  return (
    <>
      <div className="relative w-screen overflow-x-hidden xl:hidden">
        <animated.div
          ref={ref}
          {...bind()}
          className={`relative flex justify-start items-start p-2 cursor-move ${className}`}
          style={{ transform: x.interpolate(x => `translateX(${x}px)`), width: 'fit-content', touchAction: 'pan-y', minWidth: '100vw' }}>
          {children}
        </animated.div>
      </div>
      <div className="max-w-screen-lg mx-auto hidden xl:block">
        <div className={`relative w-full flex flex-wrap justify-center items-center ${className}`}>
          {children}
        </div>
      </div>
    </>
  )
}
