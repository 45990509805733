import React, { useEffect, useRef, useState } from 'react'

export default function BrandIcon({ Icon, className, style = {}, onClick, white }) {
  const ref = useRef()
  const [height, setHeight] = useState(40)
  useEffect(() => {
    if (ref.current)
      setHeight(Math.min(ref.current.getBoundingClientRect().height, 70))
  }, [ref])
  return (
    <div className={`flex ${className} ${onClick && 'cursor-pointer'} text-black`} style={style} onClick={onClick} ref={ref}>
      <Icon className="stroke-1 w-full h-full z-10" />
      <div
        className={`${white ? 'bg-white' : 'bg-orange'} absolute bottom-0 left-0`}
        style={{ width: height * 0.4, height: height * 0.4 }} />
    </div>
  )
}
