import React from 'react'
import { FaInstagram, FaFacebook, FaYoutube } from 'react-icons/fa'
import HelpCenter from '../HelpCenter'
import Button from './Button'

export default function Menu({ children, showHelp, setShowHelp, instagram, facebook, youtube }) {
  return (
    <>
      <div className="sticky top-0 w-screen lg:hidden px-5 lg:px-6 py-3 bg-orange shadow-lg z-20 text-white font-bold">
        <div
          className="w-full flex flex-col justify-between items-center transition-all duration-300 overflow-hidden text-base">
          <div className="flex flex-col lg:flex-row items-center text-center leading-tight">
            {children}
          </div>
        </div>
      </div>
      <div className="hidden lg:block fixed top-0 left-0 w-full px-8 py-2 bg-orange shadow-lg z-20 text-base text-white font-bold">
        <div className="h-full w-full flex justify-between items-center max-w-screen-lg mx-auto">
          <img src="/logo-mini-white.svg" className="h-12" />
          <div className="flex items-center">
            {children}
          </div>
          <div className="flex">
            {instagram && <Button variant="icon-white" href={instagram} Icon={FaInstagram} className="ml-2" />}
            {facebook && <Button variant="icon-white" href={facebook} Icon={FaFacebook} className="ml-2" />}
            {youtube && <Button variant="icon-white" href={youtube} Icon={FaYoutube} className="ml-2" />}
            <HelpCenter showHelp={showHelp} setShowHelp={setShowHelp} className="ml-4" />
          </div>
        </div>
      </div>
    </>
  )
}
